<template>
  <v-app-bar
    app
    flat
    v-if="!isAuthComponent"
  >
    <v-container class="d-flex">
      <v-toolbar-title class="">
         <v-img
          alt="haq.ai"
          class="shrink mt-n2"
          contain
          :src="require('@/assets/logo.svg')"
          transition="scale-transition"
          width="200px"
          height="60px"
          @click="$router.push('/')"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link v-if="isAuthenticated" to="/challenges" class="mt-2 mr-5 menu-link" >
        <span class="menu-light" :class="{'menu-dark': theme}">CHALLENGES</span>
      </router-link>

      <router-link v-if="isAuthenticated" to="/dashboard" class="mt-2 mr-5 menu-link" >
        <span class="menu-light" :class="{'menu-dark': theme}">DASHBOARD</span>
      </router-link>

      <router-link to="/contribute" class="mt-2 mr-5 menu-link">
        <span class="menu-light" :class="{'menu-dark': theme}">CONTRIBUTE</span>
      </router-link>

      <router-link to="/about"  class="mt-2 mr-5 menu-link" v-if="!loggedIn && !isAuthComponent" >
        <span class="menu-light" :class="{'menu-dark': theme}" > ABOUT US</span>
      </router-link>

      <a href="#donate" class="mt-2 mr-5 menu-link" v-if="checkHome">
        <span class="menu-light" :class="{'menu-dark': theme}">DONATE</span>
      </a>
      
    <v-spacer></v-spacer>
    <v-btn class="pa-5 mr-5 mt-n1" :class="{'black--text': theme}" to='/auth/register' color="primary" v-if="!isAuthenticated"> Signup </v-btn>
    <v-btn class="pa-5 mr-5 mt-n1" color="#979797" outlined to='/auth/login' v-if="!isAuthenticated"> Login </v-btn>

    <v-btn icon class="mt-n2" @click="switchTheme">
      <i v-if="theme" class="fas fa-sun fa-lg primary--text"></i>
      <i v-else class="fas fa-moon fa-lg primary--text"></i>
    </v-btn>

    <v-btn icon class="mt-n2" v-if="false">
      <v-icon color="primary"> mdi-bell </v-icon>
    </v-btn>
    <v-menu offset-y min-width="200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          icon 
          class="mt-n2" 
          v-bind="attrs"
          v-on="on"
          v-if="isAuthenticated" 
        >
          <v-icon color="primary"> mdi-account </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title class="text-capitalize"> {{ user.username }}</v-list-item-title>
        </v-list-item>
        <hr>
        <v-list-item @click="$store.commit('logout')">
          <v-list-item-title> Log Out </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

     <v-btn icon class="mt-n2" v-if="isAuthenticated">
      {{ getEigenTokens }} <v-icon color="primary"> mdi-lambda </v-icon>
    </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'Topbar',
  mixins: [colorMxn],
  data() {
    return {
      tokens: 0,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user', 'getEigenTokens' ]),
    isAuthComponent() {
      const routeNames = [
        'Login',
        'Auth',
        'Register',
        'ForgotPassword',
        'ResetPassword',
      ]
      const result = routeNames.find((item) => item === this.$route.name);
      return result;
    },
    loggedIn() {
      const routeNames = [
        'Dashboard',
        'Challenges',
        'ProblemEditor',
        'ChallengeOverview',
      ]
      const result = routeNames.find((item) => item === this.$route.name);
      return result;
    },
    checkHome(){
      return this.$route.name === "Home"
    }
  },
  mounted() {
    const result = localStorage.getItem('theme');
    this.$vuetify.theme.dark = JSON.parse(result);
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('theme', this.$vuetify.theme.dark);
    }
  }
}
</script>